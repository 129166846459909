





































import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'

import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)

export default Vue.extend({
  name: 'Conclusion',
  components: {
  },
  props: {
  },
  data () {
    return {
      showingCongrats:    false,
      showingLeaderboard: false,
    }
  },
  computed: {
    ...mapGetters([
      'team',
      // 'allTeams',
      'teamRankings'
    ]),
  },
  methods: {
    showLeaderboard () {
      this.$confetti.stop();
      this.showingCongrats = false;
      this.showingLeaderboard = true;
    },
  },
  mounted () {
    this.$confetti.start();
    this.showingCongrats = true;
    window.setTimeout(this.showLeaderboard, 6000);
  },
  watch: {
  }
});
